import { MdOutlineKeyboardDoubleArrowLeft, MdOutlineKeyboardDoubleArrowRight } from "react-icons/md";
import Navbar from "../Collapse-Nav/navbar";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import React from "react";
import NotFound from "../../Pages/notFound";
import CommingSoon from "../../Pages/commingSoon";
import TopHeader from "../Top-Header/topHeader";
import { AiOutlineLogout } from "react-icons/ai";
import { Button } from "@mui/material";
import Dashboard from '../../Pages/dashboard'
import Profile from "../../Pages/profile/profile";
import Orders from "../../Pages/orders/orders";
import OrderDetails from "../../Pages/orders/orders-components/orderDetails";
import Rating from "../../Pages/rating";
import Menu from "../../Pages/menu/menu";
import AddNewDish from "../../Pages/menu/Menu-Components/addNewDish";
import Offers from "../../Pages/offers/offers";
import Reports from "../../Pages/reports/reports";
import Deals from "../../Pages/deals/deals";
import Settings from "../../Pages/settings/settings";
import Users from "../../Pages/users";
import Branches from "../../Pages/branch/branches";
import AddNewDeal from "../../Pages/deals/Deals-Components/addDeal";
import EditDeal from "../../Pages/deals/Deals-Components/editDeal";
import Accounts from "../../Pages/accounts";
import EditDish from "../../Pages/menu/Menu-Components/editDish";
import Roles from "./roles";
import BranchAdminRoles from "./branchAdminRoles";
import UnAuthorized from "../../Pages/unAuthorized";
import { getMapKey } from "../Redux/Slice/common/mapSlice";
import { useTranslation } from "react-i18next";

// const Dashboard = lazy(() => import("../../Pages/dashboard"));
// const Profile = lazy(() => import("../../Pages/profile/profile"));
// const Orders = lazy(() => import("../../Pages/orders/orders"));
// const OrderDetails = lazy(() => import("../../Pages/orders/orders-components/orderDetails"));
// const Rating = lazy(() => import("../../Pages/rating"));
// const Menu = lazy(() => import("../../Pages/menu/menu"));
// const AddNewDish = lazy(() => import("../../Pages/menu/Menu-Components/addNewDish"));
// const Offers = lazy(() => import("../../Pages/offers/offers"));
// const Reports = lazy(() => import("../../Pages/reports/reports"));
// const Deals = lazy(() => import("../../Pages/deals/deals"));
// const Settings = lazy(() => import("../../Pages/settings/settings"));
// const Users = lazy(() => import("../../Pages/users"));
// const Branches = lazy(() => import("../../Pages/branch/branches"));
// const AddNewDeal = lazy(() => import("../../Pages/deals/Deals-Components/addDeal"));
// const EditDeal = lazy(() => import("../../Pages/deals/Deals-Components/editDeal"));
// const Accounts = lazy(() => import("../../Pages/accounts"));
// const EditDish = lazy(() => import("../../Pages/menu/Menu-Components/editDish"));


const MainRoutes = () => {
    const {t,i18n} = useTranslation()
    const {token} = useSelector(state=>state?.auth)
    const navigate = useNavigate()
    // console.log(token);
    const path = useLocation()
    const [navCollapse, setnavCollapse] = useState(false)
    const dispatch = useDispatch()

    const toggleNavHanlder = ()=>{
        let toggle = navCollapse
        setnavCollapse(!toggle)
    }
    // console.log(path.pathname !== '/dashboard/notfound');
    // useEffect(() => {
    //     if((!localStorage.getItem('tk') && token == null) && (path.pathname !== '/dashboard/notfound')&& (path.pathname !== '/dashboard/commingsoon')){
    //         navigate('/login')
    //     }
    // }, [])
    const logoutHandler = ()=>{
        localStorage.clear()
        navigate('/login')
        i18n.changeLanguage('en');
    }

    useEffect(() => {
        dispatch(getMapKey())
    }, [])

    // useEffect(() => {
      
    // }, [localStorage?.getItem('i18nextLng')])
    
    
    return ( 
        <div dir={localStorage?.getItem('i18nextLng') === 'ar' ? 'rtl' : 'ltr'} className={`takein-wrapper flex h-full`}>
            {/* <section className={`navbar-collapse overflow-x-hidden transition-all basis-auto duration-300 ${navCollapse ? 'w-[70px] min-w-[0px] max-w-[70px]' : 'w-[70px] min-w-[200px] max-w-[300px]'} h-full bg-mainColor relative`}> */}
            <section className={`navbar-collapse overflow-x-hidden transition-all basis-auto duration-300 ${navCollapse ? 'w-[70px] min-w-[70px] max-w-[70px]' : 'min-w-[200px] max-w-[300px]'} h-full bg-mainColor relative`}>
                <p className={`text-white text-3xl p-2 cursor-pointer ${localStorage?.getItem('i18nextLng') === 'ar' ? '[&>svg]:mr-auto' : '[&>svg]:ml-auto'}`} onClick={toggleNavHanlder}>
                    {localStorage?.getItem('i18nextLng') === 'ar' ? 
                        navCollapse ? <MdOutlineKeyboardDoubleArrowLeft  /> : <MdOutlineKeyboardDoubleArrowRight  />
                        :
                        navCollapse ? <MdOutlineKeyboardDoubleArrowRight /> : <MdOutlineKeyboardDoubleArrowLeft  />
                    }
                    
                </p>

                <div className="flex flex-col justify-between w-full h-[93%]">
                    <Navbar toggle = {navCollapse} />

                    <div className='text-white text-[17px] w-full'>
                        <Button onClick={logoutHandler} className={`flex justify-start items-center [&>span]:block [&>span]:w-full  w-full text-white text-start hover:text-red-500`}><span className="[&>svg]:inline-block [&>svg]:w-[27px] [&>svg]:h-[27px] mr-3"><AiOutlineLogout /> {navCollapse ? "" : localStorage.getItem('i18nextLng') === 'ar' ? 'تسجيل الخروج' : 'logout'}</span></Button> 
                    </div>
                </div>
            </section>

            <section className='content-wrapper h-full basis-auto grow overflow-y-auto px-2 sm:px-6 relative'>
                <div className="topheader-wrapper sticky top-0 bg-[#F2F0F6] w-full z-[999] py-4">
                    <TopHeader />
                </div>

                <div>
                    <Routes>
                        <Route path='/dashboard/profile' element={<Profile />} />
                        
                            <Route element={<BranchAdminRoles />}>
                                <Route path='/dashboard/home' element={<Dashboard />} />
                                <Route path='/dashboard/branches' element={<Branches />} />
                                <Route path='/dashboard/orders' element={<Orders />} />
                                <Route path='/dashboard/orders/orderdetails' element={<OrderDetails />} />
                                <Route path='/dashboard/rating' element={<Rating />} />
                                <Route path='/dashboard/reports' element={<Reports />} />
                                <Route path='/dashboard/settings' element={<Settings />} />
                                <Route path='/dashboard/users' element={<Users />} />
                                <Route path='/dashboard/accounts' element={<Accounts />} />
                            </Route>

                            <Route path='/dashboard/menu' element={<Menu />} />
                            <Route path='/dashboard/menu/addnewdish' element={<AddNewDish />} />
                            <Route path='/dashboard/menu/editdish' element={<EditDish />} />
                            <Route path='/dashboard/offers' element={<Offers />} />
                            <Route path='/dashboard/deals' element={<Deals />} />
                            <Route path='/dashboard/deals/addnewdeal' element={<AddNewDeal />} />
                            <Route path='/dashboard/deals/editdeal' element={<EditDeal />} />

                            <Route path='/dashboard/unauthorized' element={<UnAuthorized />} />
                            <Route path='/dashboard/notfound' element={<NotFound />} />
                            <Route path='/dashboard/commingsoon' element={<CommingSoon />} />
                            <Route path='*' element={<CommingSoon />} /> {/* this path used to display this component when ever there is no match to any route */}
                        
                    </Routes>
                </div>
            </section>
        </div>

     );
}
 
export default MainRoutes;