import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import { errorToast } from "../Toast-Messages/toastMessage";

export const changePassword = createAsyncThunk("changePasswordFunc", async(values, {rejectWithValue,dispatch})=>{
    try {
        const res = await toast.promise(axios.put(`/profile/change-password`,values),{
            pending: 'loading...',
            success: 'Password Changed Successfully 👌',
            // error: 'Promise rejected 🤯'
        },{toastId : "changePasswordToast"}
        )
        // console.log(res);
        return res.data

    } catch(error){
        // console.log(error.response);
        if (error.response && error.response.data) {
            if(Array?.isArray(error.response.data.message)){
                error.response.data.message && error.response.data.message?.map(item=>dispatch(errorToast(item)))
            } else{
                dispatch(errorToast(error.response.data.message))
            }
            return rejectWithValue(error.response.data.message)
        } else if(navigator.onLine === false){
            dispatch(errorToast('Check Internet Connection'))
        }
        
        else {
            return rejectWithValue(error.message)
        }
    }
})


const initialState = {
    loading: false,
    error : null,
    status : ''
}
export const ChangePassword = createSlice({ 
    name: "ChangePassword-Func", 
    initialState,
    reducers: {
    },

    extraReducers : (builder) =>{
        builder
        .addCase(changePassword.pending, (state,action)=>{
            state.loading = true;
            state.error = ""
        })

        .addCase(changePassword.fulfilled, (state,action)=>{
            state.loading = false;
            state.error = ""
            state.status = action.payload
            localStorage.clear()
            // console.log(action.payload);
        })

        .addCase(changePassword.rejected, (state,action)=>{
            state.loading = false;
            state.error = action.payload
        })

    }
});

export const {logout} = ChangePassword.actions

export default ChangePassword.reducer

