import { Box, ListItemButton, Modal } from "@mui/material";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { changePassword } from "../../../Components/Redux/Slice/Profile/changePasswordSlice";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FaRegTimesCircle } from "react-icons/fa";


const ProfileModal = ({open,close,t}) => {
    const form = useForm()
    const {register,control,handleSubmit,formState,setValue,getValues,reset} = form
    const {errors,isDirty} = formState
    const navigate = useNavigate()

    const {changepassword} = useSelector(state=>state)
    const  dispatch = useDispatch()

    // console.log(changepassword);

    const modalSubmitHandler = (data)=>{
        // console.log(data);
        dispatch(changePassword(data))
        
    }
    
    useEffect(() => {
        if(changepassword?.status?.message === 'SUCCESS' && !localStorage.getItem('tk')){
            navigate('/login')
        }
    }, [changepassword?.status])
    
    
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        bgcolor: 'background.paper',
        // border: '2px solid #000',
        boxShadow: 3,
        p: 4,
        borderRadius : 5,
    };
    

    return ( 
        <Modal
                open={open}
                onClose={()=>{
                    close()
                    reset()
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                dir={localStorage?.getItem('i18nextLng') === 'ar' ? 'rtl' : 'ltr'}
            >
                <Box sx={style}>
                    <div className={`flex items-center justify-between ${localStorage.getItem('i18nextLng') === 'ar' ? 'flex-row-reverse' : 'flex-row'}`}>
                        <h2 className="text-[#333333] font-bold mb-5 font-playfair capitalize">{t('profile.change_password')}</h2>

                        <div className="mb-2">
                            <span className="text-mainRed text-xl text-right [&>svg]:ml-auto cursor-pointer" onClick={()=>{
                                close()
                                reset()
                            }}><FaRegTimesCircle /></span>
                        </div>
                    </div>
                    <form className="profile-image-wrapper mt-5" onSubmit={handleSubmit(modalSubmitHandler)} noValidate>

                        <div className="form-input-wrapper mt-8 [&>div]:mb-4 [&>div>label]:capitalize [&>div>label]:text-[#333] [&>div>label]:text-sm [&>div>label]:block [&>div>label]:mb-2 [&>div>label]:font-semibold [&>div>input]:bg-[#f3f6f9] [&>div>input]:w-full [&>div>input]:p-3 [&>div>input]:focus:outline-slate-400 [&>div>input]:rounded-md [&>div>input]:text-mainColor [&>div>input]:mb-1">
                            <div>
                                <label>{t('profile.modal.current_password')}</label>
                                <input type="password" id="currentPassword"
                                {...register('currentPassword',{
                                    required:{
                                        value : true,
                                        message : t('common.required')
                                    }
                                })} />

                                {errors?.currentPassword?.message ? <p className="capitalize text-sm text-danger">{errors?.currentPassword?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">{t('profile.modal.current_password_helper')}</p>}
                            </div>

                            <div>
                                <label>{t('profile.modal.new_password')}</label>
                                <input type="password" id="newPassword"
                                {...register('newPassword',{
                                    required:{
                                        value : true,
                                        message : t('common.required')
                                    },
                                    
                                })} />

                                {errors?.newPassword?.message ? <p className="capitalize text-sm text-danger">{errors?.newPassword?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">{t('profile.modal.new_password_helper')}</p>}
                            </div>

                            <div>
                                <label>{t('profile.modal.confirm_password')}</label>
                                <input type="password" id="confirmPassword"
                                {...register('confirmPassword',{
                                    required:{
                                        value : true,
                                        message : t('common.required')
                                    }
                                })} />

                                {errors?.confirmPassword?.message ? <p className="capitalize text-sm text-danger">{errors?.confirmPassword?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">{t('profile.modal.confirm_password_helper')}</p>}
                            </div>

                            <div className="profile-submit-btn-wrapper w-[150px] ml-auto mt-8">
                                <ListItemButton disabled={changepassword?.loading } className=" disabled:opacity-50 disabled:cursor-not-allowed justify-center bg-mainColor px-8 py-3 text-white text-sm font-semibold rounded-md"><button type="submit"  className="w-full">{t('profile.modal.change_btn')}</button></ListItemButton>
                            </div>

                        </div>

                        

                    </form>
                </Box>
            </Modal>
     );
}
export default ProfileModal;