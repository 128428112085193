import { Button } from "@mui/material";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { saveData, toggleStatus } from "../Redux/Slice/MultiStepForm/multiStepFormSlice";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

const StepOne = ({ nextStep }) => {
  const {t, i18n} = useTranslation()
  const { handleSubmit, register, setValue, getValues,formState } = useForm();
  const {errors} = formState

  const {values} = useSelector(state=>state?.multiStepForm)
  const dispatch = useDispatch()

  useEffect(() => {
        setValue('firstName', values?.firstName); 
        setValue('lastName', values?.lastName); 
        setValue('personalPhoneNumber', values?.personalPhoneNumber); 
        setValue('personalEmail', values?.personalEmail); 
        setValue('nationalId', values?.nationalId); 
  }, [setValue,values])
  

    const submitFormData = (data) => {
      if(data && Object.keys(errors).length === 0){
        dispatch(saveData(data))
        dispatch(toggleStatus({stepOne:true}))
        nextStep()
      }
    };

    return (
      <div>
        <form onSubmit={handleSubmit(submitFormData)} noValidate className="form flex flex-wrap gap-y-3 md:gap-x-1 xl:gap-x-1 justify-between [&>div>label]:block [&>div>label]:capitalize [&>div>label]:text-sm [&>div>label]:text-[#515151] [&>div>label]:font-semibold [&>div>label]:mb-1 [&>div>input]:w-full [&>div>input]:py-3 [&>div>input]:px-2 [&>div>input]:rounded-md [&>div>input]:bg-[#EEF1F5]">
          <div className="basis-full lg:basis-[49%]">
            <label htmlFor="firstName">first Name</label>
            <input type="text" id="firstName" {...register('firstName',{
                required: {
                  value: true,
                  message: t('common.required'),
                },
              })}
              defaultValue={values?.firstName}
            />
            {errors?.firstName && (
              <p style={{ color: "red" }}>{errors.firstName.message}</p>
            )}
          </div>

          <div className="basis-full lg:basis-[49%]">
            <label htmlFor="lastName">last Name</label>
            <input
              type="text"
              id="text"
              {...register("lastName",{
                required: {
                  value: true,
                  message: t('common.required'),
                },
              })}
              defaultValue={values?.lastName}
            />
            {errors?.lastName && (
              <p style={{ color: "red" }}>{errors.lastName.message}</p>
            )}
          </div>

          <div className="basis-full lg:basis-[49%]">
            <label>phone number</label>
            <input type="text" id="personalPhoneNumber"
                {...register('personalPhoneNumber',{
                    required:{
                        value : true,
                        message : t('common.required')
                    },
                    minLength : {
                        value : 11,
                        message : 'min length is 11 digits'
                    },
                    maxLength : {
                        value : 22,
                        message : 'max length is 22 digits'
                    },
                    pattern:{
                      value: /^(\+)?\d+$/,
                      message: 'Only numbers and (+) sign the beginning allowed'
                  }
                })}
                  minLength={11}
                  maxLength={22}
                  defaultValue={values?.personalPhoneNumber}
              />

            {errors?.personalPhoneNumber && (
              <p style={{ color: "red" }}>{errors.personalPhoneNumber.message}</p>
            )}
          </div>

          <div className="basis-full lg:basis-[49%]">
            <label>email address</label>
            <input type="email" id="personalEmail"
                {...register('personalEmail',{
                    pattern:{
                        value : /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-]+)(\.[a-zA-Z]{2,5}){1,2}$/,
                        message : 'Wrong Email Format'
                    },
                    required:{
                        value : true,
                        message : t('common.required')
                    },
                    minLength : {
                        value : 3,
                        message : 'min length is 3 characters'
                    },
                    maxLength : {
                        value : 50,
                        message : 'max length is 50 characters'
                    },
                })}
                minLength={3}
                maxLength={50}
                defaultValue={values?.personalEmail}
            />

            {errors?.personalEmail && (
              <p style={{ color: "red" }}>{errors.personalEmail.message}</p>
            )}
          </div>

          <div className="basis-full">
            <label htmlFor="nationalId">National ID</label>
            <input type="text" id="nationalId" {...register('nationalId',{
                required: {
                  value: true,
                  message: t('common.required'),
                },
              })}
              defaultValue={values?.nationalId}
            />
            {errors?.nationalId && (
              <p style={{ color: "red" }}>{errors.nationalId.message}</p>
            )}
          </div>

          <div className="basis-full text-right">
            <Button type="submit" className="bg-mainGreen text-white mt-4">
              Continue
            </Button>
          </div>

        </form>
      </div>
    );
  };
  
  export default StepOne;
  