import { useLocation } from "react-router-dom";
import OrderDetailsLeftSection from "./OrderDetailsSections/leftSection";
import OrderDetailsRightSection from "./OrderDetailsSections/rightSections";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAssignHistory, getInvoice, getOrdersById } from "../../../Components/Redux/Slice/Orders/orders";
import BreadCrumb from "../../../Components/BreadCrumb/breadCrumb";
import Overlay from '../../../Components/Common/overlay'
import { Box, Button, Modal } from "@mui/material";
import { IoCloseCircleOutline } from "react-icons/io5";
import receipt from '../../../Assets/imgs/receipt-bg.svg'
import DataTable from "react-data-table-component";
import { dateFormate } from "../../../Components/Common/dateFormate";
import { TableStyles } from "../../../Components/Common/tableStyle";
import { useTranslation } from "react-i18next";
import { LuArrowBigLeft, LuArrowBigRight } from "react-icons/lu";

const OrderDetails = () => {
    const {t,i18n} = useTranslation()
    const location = useLocation()
    let id = location?.state?.id
    const dispatch = useDispatch()
    const {singleOrder} = useSelector(state=>state.orders)
    const {invoice} = useSelector(state=>state.orders)
    const {loading} = useSelector(state=>state.orders)
    // console.log(location?.state);
    useEffect(() => {
        dispatch(getOrdersById(id))
        dispatch(getInvoice(id))
        dispatch(getAssignHistory(id))
    }, [dispatch,id])
    
    const paths = [
        {path:'/dashboard/orders',name:'orders',nameAr:'الطلبات'},
        {path:'',name:'order details',nameAr:'تفاصيل الطلب'},
    ]

    // modal 

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        maxWidth: '500px',
        // maxWidth : '500px',
        height: '90%',
        // minHeight: '600px',
        bgcolor: 'transparent',
        // border: '2px solid #000',
        // boxShadow: 2,
        p:2,
        borderRadius : 5,
        // backgroundImage : `url(${receipt})`,
        // backgroundPosition : 'top'
        // backgroundRepeat : 'no-repeat'
    };

    const [openModal, setopenModal] = useState(false)
    const handelOpen = ()=>{
        setopenModal(true)
    }
    const handelClose = ()=>{
        setopenModal(false)
    }


    // transactions

    const [transPage,settransPage] = useState(1)
    const [transSize,settransSize] = useState(10)

    const transColumns = [
        {
            name: `${localStorage.getItem('i18nextLng') === 'ar' ? 'اسم مالك الحساب' : 'Account Holder Name'}`,
            selector: row => row.accountHolderName,
            sortable: false,
        },
        {
            name: `${localStorage.getItem('i18nextLng') === 'ar' ? 'نوع الحساب' : 'Account Holder Type'}`,
            selector: row => row.accountHolderType,
            sortable: false,
        },
        {
            name: `${localStorage.getItem('i18nextLng') === 'ar' ? 'الكمية' : 'Amount'}`,
            cell: row =><><span className={`${row?.outward ? 'text-mainGreen' : 'text-mainRed'}`}>{row.transactionAmount}</span>&nbsp; <span> SAR</span></>,
            sortable: false,
        },
        
        {
            name: `${localStorage.getItem('i18nextLng') === 'ar' ? 'تم انشاءه في' : 'Created at'}`,
            selector: row => dateFormate(row.createdAt),
            sortable: false,
        },
        {
            name: `${localStorage.getItem('i18nextLng') === 'ar' ? 'الحاله' : 'status'}`,
            selector: row => <span className={`block px-2 py-[6px] rounded-md bg-opacity-10 min-w-[130px] font-semibold text-center capitalize ${row?.transactionStatus === 'COMPLETED' ? 'text-mainGreen bg-mainGreen' : row?.transactionStatus === 'PENDING' ? 'text-amber-400 bg-amber-400' : row?.transactionStatus === 'CANCELLED' ? 'text-mainRed bg-mainRed' : row?.transactionStatus === 'ON_HOLD' ? 'text-mainYellow bg-mainYellow' : null}`}>{row?.transactionStatus?.toLowerCase()}</span>,
            sortable: false,
        },
        // {
        //     name: 'Order ID',
        //     cell: row => <Button><Link to='/dashboard/orders/orderdetails' state={{id:row?.orderId}}>{row?.orderId}</Link></Button> ,
        //     sortable: false,
        // },
        // {
        //     name: 'Actions',
        //     allowOverflow: true,
        //     button : true,
        //     cell: ActionCell
                
        // },
    ];
    
    const transData = singleOrder?.data?.transactions?.filter( (item)=>item?.accountHolderType === 'PARTNER' )

    // console.log(transData);

    const handleTransPageChange = page => {
        settransPage(page);
    };
    const handleTransactionRowChange = (perPage) => {
        settransSize(perPage);
    };

    useEffect(() => {
        // console.log(size)
        // dispatch(getTransaction({id,transPage,transSize}))
    }, [dispatch, transPage,transSize])

    return ( 
        <article className="order-details-wrapper pb-6">
            <div className="order-details-title-wrapper text-[#464E5F] font-bold text-xl md:text-2xl lg:text-3xl capitalize mb-5">
                <h1>{t('order_details.title')} #{id}</h1>
            </div>

            <div className="flex items-center justify-between -mt-4">
                <div>
                    <BreadCrumb paths={paths} seperator='/' />
                </div>

                <div className="flex gap-2 items-center mb-5 [&>button]:border-[1px]">
                    
                    <div>
                        <Button onClick={handelOpen} sx={{ borderBottom: 1,pb:'1px' }}  className="capitalize text-mainGreen border-b border-mainGreen">{t('order_details.view_receipt_btn')}</Button>
                    </div>

                    <div>
                        <Button title={!invoice?.data ? t('order_details.no_invoice') : null} disabled={!invoice?.data || loading} className="disabled:bg-opacity-25 disabled:bg-gray-800 disabled:text-gray-400 disabled:border-none disabled:cursor-not-allowed disabled:pointer-events-auto  capitalize text-mainColor font-semibold font-Ubuntu bg-transparent hover:bg-mainColor hover:text-white border-solid border-[2px] border-mainColor">
                            <a href={invoice?.data?.directLink} download>{t('order_details.download_invoice')}</a>
                        </Button>
                    </div>
                </div>
                
            </div>

            {/* <div><BreadCrumb paths={paths} seperator='/' /></div> */}
            <div className="flex flex-wrap md:flex-nowrap justify-between gap-y-2 md:gap-x-3 lg:gap-x-5">
                <section className="order-details-lefft-section-wrapper basis-full lg:basis-[33%]">
                    <OrderDetailsLeftSection data={singleOrder?.data} t={t} />
                </section>

                <section className="order-details-right-section-wrapper basis-full lg:basis-[67%] text-left">
                    <OrderDetailsRightSection data={singleOrder?.data} t={t} />
                </section>
            </div>

            <div className="mt-6">
                <h2 className="text-lg text-mainColor font-bold mb-2 capitalize">{t('order_details.order_transaction.title')}</h2>
                <div className="relative bg-white rounded-md"> 
                    <DataTable
                        columns={transColumns}
                        data={transData}
                        // pagination
                        // paginationPerPage = {transSize}
                        // paginationRowsPerPageOptions = {[10,50,100]}
                        // paginationServer
                        // paginationTotalRows={accounts?.transactions?.meta?.total}
                        onChangePage={handleTransPageChange}
                        onChangeRowsPerPage={handleTransactionRowChange}
                        customStyles={TableStyles}
                        highlightOnHover
                        // sortServer
                        // onSort={handleRemoteSort}
                    />

                {/* {accounts?.transLoading ?
                    <div className='absolute top-0 bottom-0 left-0 right-0 bg-[rgba(0,0,0,.1)] backdrop-blur-sm flex justify-center items-center'>
                        <p className='w-20 h-20 border-4 border-t-0 border-b-0 border-gray-500 rounded-full animate-spin'></p>
                    </div> : null} */}

                </div>
            </div>

            {loading ? <Overlay /> : null}

            <>
                <Modal
                    open={openModal}
                    onClose={handelClose}
                    aria-labelledby="receipt-title"
                    aria-describedby="receipt-modal-description"
                >

                    <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2" dir={localStorage.getItem('lang') === 'ar' ? 'rtl' : 'ltr'}>
                        <div className="w-[430px] h-[640px] m-auto relative overflow-hidden">
                            <div className="w-full h-full absolute z-1 top-0 bottom-0 left-0 right-0">
                                <img src={receipt} alt="receipt-paper" className="object-cover h-full" />
                            </div>

                            <div className="absolute top-2 right-2 z-20  w-[96%] overflow-hidden">
                                <div className="pb-3 border-b-[2px] border-dashed w-full text-right">
                                    <span className="text-3xl text-mainRed z-20 cursor-pointer w-fit block ml-auto" onClick={handelClose}> <IoCloseCircleOutline /> </span>
                                </div>
                                <div className="receipt-body-wrapper h-[300px] overflow-y-auto p-2 [&>div>p]:mb-2 pb-3 border-b-[2px] border-dashed">
                                    {singleOrder?.data?.orderItems?.map( (item,index,array)=>(
                                        <div key={item?.id} className={`capitalize mb-2 pb-2 ${index !== array.length - 1 ? 'border-b' : ''}`}>
                                            <p className="item-name text-[#333] flex items-center justify-between">
                                                <span className="font-semibold">{localStorage.getItem('lang') === 'ar' ? item?.itemNameAr : item?.itemName}</span>
                                                <span>SAR {item?.itemPrice}</span>
                                            </p>
                                            {item?.options && item?.options?.length !== 0 ? <p className="text-sm text-mainColor capitalize font-semibold">{t('deals.form.options.title')}</p> : null}
                                            { item?.options?.map(item=>(
                                                <>
                                                    <p className="options text-[#999] text-sm flex items-center justify-between">
                                                        <span>
                                                            <span>{item?.quantity} X </span>
                                                            <span className="font-semibold text-[#727272]">{localStorage.getItem('lang') === 'ar' ? item?.nameAr : item?.name}</span>
                                                            <span className="[&>svg]:inline-block mx-1">{localStorage.getItem('lang') === 'ar' ? <LuArrowBigLeft /> : <LuArrowBigRight /> }</span>
                                                            <span>{item?.price}</span>
                                                        </span>
                                                        <span>SAR {parseInt(item?.price) * parseInt(item?.quantity)}</span>
                                                    </p> 
                                                </>
                                            )) 
                                            }
                                            {item?.extras && item?.extras?.length !== 0 ? <p className="text-sm text-mainColor capitalize font-semibold">{t('deals.form.extras.title')}</p> : null}

                                            {item?.extras?.map( (ext,index2)=>(
                                                <p key={index} className="extras text-[#999] text-sm flex items-center justify-between">
                                                    <div>
                                                        <span>{ext?.quantity} X </span>
                                                        <span>{localStorage.getItem('lang') === 'ar' ? ext?.nameAr : ext?.name}</span>
                                                    </div>
                                                    <span>SAR {ext?.price}</span>
                                                </p>
                                            ) )}

                                            <div className="mt-6 flex items-center justify-between text-sm">
                                                <p className="item-quantity text-[#999]">{item?.itemQuantity} x</p>
                                                {/* <p>SAR {parseInt(item?.itemPrice)?.toFixed(2)}</p> */}
                                                <p>SAR {((parseInt(item?.itemPrice) + (item?.options ? item?.options?.reduce((acc, item) => acc + (item.price * item.quantity), 0) : 0) + (item?.extras ? item?.extras?.reduce((acc, item)=>{return parseInt(acc) + parseInt(item?.price)},0) : 0)) * parseInt(item?.itemQuantity) ) }</p>
                                            </div>
                                        </div>
                                    ) )}

                                </div>

                                <div className="receipt-price-wrapper mt-3">

                                    <div className="sub-total-wrapper [&>div]:mb-5">
                                        <div className="flex items-center justify-between">
                                            <p className="font-semibold">{t('order_details.receipt.sub_total')}</p>
                                            <p className="font-semibold">SAR {parseInt(singleOrder?.data?.originalPrice)?.toFixed(2)}</p>
                                        </div>

                                        <div className="flex items-center justify-between pb-2 border-b text-sm">
                                            <p className="text-[#999]">{t('order_details.receipt.discount_amount')}</p>
                                            <p className="font-semibold">SAR {parseInt(singleOrder?.data?.discountAmount)?.toFixed(2)}</p>
                                        </div>

                                        <div className="flex items-center justify-between text-sm">
                                            <p className="text-[#999]">{t('order_details.receipt.delivery_charges')}</p>
                                            <p className="font-semibold">SAR {parseInt(singleOrder?.data?.deliveryCharges)?.toFixed(2)}</p>
                                        </div>

                                        <div className="flex items-center justify-between pb-2 border-b text-sm">
                                            <p className="text-[#999]">{t('order_details.receipt.wallet_balance_used')}</p>
                                            <p className="font-semibold">SAR {parseInt(singleOrder?.data?.walletBalanceUsed)?.toFixed(2)}</p>
                                        </div>
                                    </div>

                                    <div className="total-amount-wrapper capitalize text-mainColor font-semibold flex items-center justify-between">
                                        <span>{t('order_details.receipt.total_amount')}</span>

                                        <span>SAR {parseInt(singleOrder?.data?.finalPrice)?.toFixed(2)}</span>
                                    </div>
                                </div>                                
                            </div>

                            
                        </div>

                    </div>
                </Modal>
            </>
        </article>
    );
}

export default OrderDetails;