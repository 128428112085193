import { FiArrowDownRight, FiArrowUpRight } from 'react-icons/fi';
import './reportRight.scss'
const ReportRevenue = ({t}) => {
    return ( 
        <div className="report-right-summary-wrapper w-full text-white rounded-lg p-3 py-6 flex flex-col justify-between items-center text-center capitalize [&>div]:mb-2">
            <div>
                <h2 className="text-sm ">{t('reports.total_revenue')}</h2>
            </div>

            <div>
                <p className="text-3xl font-extrabold">16.00</p>
            </div>

            <div>
                <span>SAR</span>
            </div>

            <div className='flex justify-between mt-5 w-full gap-5 [&>div]:grow [&>div]:bg-white [&>div]:rounded-lg [&>div]:p-3 [&>div]:flex [&>div]:items-start'>

                <div>
                    <div className={`text-mainGreen ${localStorage.getItem('i18nextLng') === 'ar' ? 'ml-3' : 'mr-3'} bg-[#5cac7d41] text-xl w-7 h-7 rounded-[50%] flex justify-center items-center`}>
                        <FiArrowUpRight />
                    </div>

                    <div className='text-left'>
                        <p className='text-mainGreen text-sm font-semibold'>SAR 7.13</p>
                        <p className='text-[11px] text-[#B5B5C3] text-center'>{t('reports.income')}</p>
                    </div>
                </div>

                <div>
                    <div className={`text-mainColor ${localStorage.getItem('i18nextLng') === 'ar' ? 'ml-3' : 'mr-3'} bg-[#442b7e41] text-xl w-7 h-7 rounded-[50%] flex justify-center items-center`}>
                        <FiArrowDownRight />
                    </div>

                    <div className='text-left'>
                        <p className='text-mainColor text-sm font-semibold'>SAR 7.15</p>
                        <p className='text-[11px] text-[#B5B5C3] text-center'>{t('reports.expense')}</p>
                    </div>
                </div>
            </div>
        </div>
    );
}
 
export default ReportRevenue;