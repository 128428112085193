import { useNavigate } from 'react-router-dom';
import { MenuItem, Select} from "@mui/material";
import logo from '../Assets/imgs/collapse-logo.svg'
import linth from '../Assets/imgs/Linth.svg'
import termsLogo from '../Assets/imgs/terms-content-logo.svg'
import { GrPrevious } from 'react-icons/gr';
import { useTranslation } from 'react-i18next';

const Privacy = () => {
    const {t,i18n} = useTranslation()
    const navigate = useNavigate();

    const changeLanguage = (lang)=>{
        i18n.changeLanguage(lang?.target.value)
        // console.log(lang?.target.value);
    }

    const privacyList = [
        {id:1,title:t('privacyPolicy.privacyList.term1.title'),body:[
            {id:1.1,title:t('privacyPolicy.privacyList.term1.body.subbody1.title'),body:t('privacyPolicy.privacyList.term1.body.subbody1.body')},
            {id:1.2,title:t('privacyPolicy.privacyList.term1.body.subbody2.title'),body:t('privacyPolicy.privacyList.term1.body.subbody2.body')},
            {id:1.3,title:t('privacyPolicy.privacyList.term1.body.subbody3.title'),body:t('privacyPolicy.privacyList.term1.body.subbody3.body')},
        ]},

        {id:2,title:t('privacyPolicy.privacyList.term2.title'),body:[
            {id:2.1,title:t('privacyPolicy.privacyList.term2.body.subbody1.title'),body:t('privacyPolicy.privacyList.term2.body.subbody1.body')},
            {id:2.2,title:t('privacyPolicy.privacyList.term2.body.subbody2.title'),body:t('privacyPolicy.privacyList.term2.body.subbody2.body')},
            {id:2.3,title:t('privacyPolicy.privacyList.term2.body.subbody3.title'),body:t('privacyPolicy.privacyList.term2.body.subbody3.body')},
            {id:2.4,title:t('privacyPolicy.privacyList.term2.body.subbody4.title'),body:t('privacyPolicy.privacyList.term2.body.subbody4.body')},
            {id:2.5,title:t('privacyPolicy.privacyList.term2.body.subbody5.title'),body:t('privacyPolicy.privacyList.term2.body.subbody5.body')},
            {id:2.6,title:t('privacyPolicy.privacyList.term2.body.subbody6.title'),body:t('privacyPolicy.privacyList.term2.body.subbody6.body')},
            {id:2.7,title:t('privacyPolicy.privacyList.term2.body.subbody7.title'),body:t('privacyPolicy.privacyList.term2.body.subbody7.body')},
        ]},

        {id:3,title:t('privacyPolicy.privacyList.term3.title'),body:[
            {id:3.1,title:t('privacyPolicy.privacyList.term3.body.subbody1.title'),body:t('privacyPolicy.privacyList.term3.body.subbody1.body')},
            {id:3.2,title:t('privacyPolicy.privacyList.term3.body.subbody2.title'),body:t('privacyPolicy.privacyList.term3.body.subbody2.body')},
            {id:3.3,title:t('privacyPolicy.privacyList.term3.body.subbody3.title'),body:t('privacyPolicy.privacyList.term3.body.subbody3.body')},
            {id:3.4,title:t('privacyPolicy.privacyList.term3.body.subbody4.title'),body:t('privacyPolicy.privacyList.term3.body.subbody4.body')},
            {id:3.5,title:t('privacyPolicy.privacyList.term3.body.subbody5.title'),body:t('privacyPolicy.privacyList.term3.body.subbody5.body')},
            {id:3.6,title:t('privacyPolicy.privacyList.term3.body.subbody6.title'),body:t('privacyPolicy.privacyList.term3.body.subbody6.body')},
            {id:3.7,title:t('privacyPolicy.privacyList.term3.body.subbody7.title'),body:t('privacyPolicy.privacyList.term3.body.subbody7.body')},
            {id:3.8,title:t('privacyPolicy.privacyList.term3.body.subbody8.title'),body:t('privacyPolicy.privacyList.term3.body.subbody8.body')},
            {id:3.9,title:t('privacyPolicy.privacyList.term3.body.subbody9.title'),body:t('privacyPolicy.privacyList.term3.body.subbody9.body')},
            {id:3.10,title:t('privacyPolicy.privacyList.term3.body.subbody10.title'),body:t('privacyPolicy.privacyList.term3.body.subbody10.body')},
        ]},

        {id:4,title:t('privacyPolicy.privacyList.term4.title'),body:[
            {id:4.1,title:t('privacyPolicy.privacyList.term4.body.subbody1.title'),body:t('privacyPolicy.privacyList.term4.body.subbody1.body')},
        ]},

        {id:5,title:t('privacyPolicy.privacyList.term5.title'),body:[
            {id:5.1,title:t('privacyPolicy.privacyList.term5.body.subbody1.title'),body:t('privacyPolicy.privacyList.term5.body.subbody1.body')},
        ]},

        {id:6,title:t('privacyPolicy.privacyList.term6.title'),body:[
            {id:6.1,title:t('privacyPolicy.privacyList.term6.body.subbody1.title'),body:t('privacyPolicy.privacyList.term6.body.subbody1.body')},
        ]},

        {id:7,title:t('privacyPolicy.privacyList.term7.title'),body:[
            {id:6.1,title:t('privacyPolicy.privacyList.term7.body.subbody1.title'),body:t('privacyPolicy.privacyList.term7.body.subbody1.body')},
        ]},

        {id:8,title:t('privacyPolicy.privacyList.term8.title'),body:[
            {id:7.1,title:t('privacyPolicy.privacyList.term8.body.subbody1.title'),body:t('privacyPolicy.privacyList.term8.body.subbody1.body')},
        ]},

        {id:9,title:t('privacyPolicy.privacyList.term9.title'),body:[
            {id:8.1,title:t('privacyPolicy.privacyList.term9.body.subbody1.title'),body:t('privacyPolicy.privacyList.term9.body.subbody1.body')},
        ]},

        {id:10,title:t('privacyPolicy.privacyList.term10.title'),body:[
            {id:9.1,title:t('privacyPolicy.privacyList.term10.body.subbody1.title'),body:t('privacyPolicy.privacyList.term10.body.subbody1.body')},
        ]},
    ]

    return ( 
        <article className="terms-wrapper h-screen">
            <div className='flex w-full h-full'>
                <section className="left-sec-wrapper min-w-[200px] max-w-[300px] h-full relative bg-mainColor">
                    <div className='takeIn-logo-wrapper text-center w-full absolute left-1/2 top-[25%] translate-x-[-50%]'>
                        <img src={logo} alt='takinIN-logo' className='w-28 h-28 m-auto' />
                    </div>

                    <div className='takeIn-linth-wrapper text-center w-full absolute left-0 bottom-0'>
                        <img src={linth} alt='takinIN-logo' className='w-full' />
                    </div>
                </section>

                <section className='right-sec-wrapper grow h-full'>
                    <div className='terms-content-wrapper pt-10 h-full px-6'>
                        <div className='terms-title-wrapper capitalize flex justify-between items-center'>
                            <div className=' flex items-center gap-x-3'>
                                <div className='previous-page-icon text-xl cursor-pointer' onClick={()=>navigate(-1)}>
                                    <GrPrevious />
                                </div>
                                <h2 className='font-normal text-xl text-[#464E5F] font-playfair'>{t('privacyPolicy.title')}</h2>
                            </div>

                            <div>

                                <Select 
                                    className="bg-mainColor text-white [&>div]:p-2 w-10 h-10 text-center [&>svg]:hidden text-sm" 
                                    sx={{'& fieldset': {border:0}}}
                                    defaultValue={(localStorage?.getItem('i18nextLng') === 'en-US' || localStorage?.getItem('i18nextLng') === 'en' || !localStorage.getItem('i18nextLng')) ? 'en' : 'ar'} 
                                    onChange={(lang)=>changeLanguage(lang)}
                                >
                                    <MenuItem value={'en'}>EN</MenuItem>
                                    <MenuItem value={'ar'}>AR</MenuItem>
                                </Select>
                            </div>
                        </div>

                        <div className='mt-5 bg-white rounded-lg px-3 py-5 flex gap-x-4 w-full h-[90%] items-start'>
                            
                            <section className="content-logo-wrapper basis-[35%] my-auto">
                                <img src={termsLogo} alt='terms-reading' className='w-full' />
                            </section>

                            <section className='content-text-wrapper text-[#333333] basis-[65%]' dir={localStorage?.getItem('i18nextLng') === 'ar' ? 'rtl' : 'ltr'}>
                                <h2 className='capitalize font-bold text-xl tracking-wider'>{t('privacyPolicy.title')}</h2>

                                <section className="terms-list-wrapper mt-1 h-auto max-h-[500px] overflow-hidden overflow-y-auto">
                                    <section className='mandataory-terms text-xs text-[#333] mb-6'>
                                        <p className='text-sm font-semibold underline mb-2'>{t('privacyPolicy.date')}</p>
                                        <p>{t('privacyPolicy.intro')}</p>
                                    </section>
                                    <ol type="1" className="list-decimal px-6">
                                        {privacyList?.map( (item)=>{
                                            return(
                                                <li key={item?.id} className="mb-3">
                                                    <p className="font-semibold text-mainColor text-lg capitalize">{item?.title?.toLowerCase()}</p>
                                                    <ol type="I" className="privacy-sub-list px-4">
                                                        {item?.body?.map(sub=>{
                                                            return(
                                                                <li key={sub?.id}>
                                                                    <p className="text-[#555] text-lg font-bold">{sub.title}</p>
                                                                    <p className="text-[#555] text-sm">{sub.body}</p>
                                                                </li>
                                                            )
                                                        })}
                                                    </ol>
                                                </li>
                                            )
                                        } )}
                                    </ol>
                                </section>
                            </section>
                        </div>
                    </div>
                </section>
            </div>
        </article>
    );
}

export default Privacy;